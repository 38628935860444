import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiAppBar } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Typography,
  Button,
  IconButton,
  Theme,
  Link,
  Divider,
  Hidden,
  Dialog,
  FormControl,
  Select,
  MenuItem,
  Badge,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserIconPopover } from '../user-icon-popover/user-icon-popover.component';
import { getHistory } from '../../utils/history.util';
import Tour from './components/tour.component';
import { DialogBonusCard } from './components/dialog-bonus-card.component';
import { CasinoBonusesDto } from '../../models/CasinoLobbyV2/CasinoBonusesDto';
import { CashierDialog } from './components/cashier-components/cashier-dialog.component';
import { GetAmountWithDecimals, GetAmountWithOutDecimals } from '../functions/common';
import { Player } from '../right-nav/player';
import Draggable from 'react-draggable';
import CancelIcon from '@material-ui/icons/Cancel';
import { ITvChannels } from '../../models/tv-channels';
import IdentityDialog from './components/identity-dialog.component';
import { IIdentityDialog } from '../../models/users';
import { GetCurrentHeaderButtonView, GetCurrentHeaderButtonViewInfo, HeaderButtonViewEnum } from '../../enum/components/component-enum';
import AdDialog from './components/ad-dialog.componenent';
import EmailIcon from '@material-ui/icons/Email';
import { IMessageByUser, IMessageMarkAsRead } from '../../models/Messages/messages';
import { getAppSettings } from '../../utils';
/* import { Cashier } from '@ymt-projects/ymt-common'; */

declare global {
  interface Window {
    LO: any;
  }
}

// const openLOLiveChat = () => {
//   if (window.LO != null) {
//     if (window.LO.messenger != undefined && window.LO.messenger != null) {
//       window.LO.messenger.open();
//       console.log('opened LO Chat');
//     }
//   }
// };

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/top-bg.png` + ')',
    color: 'white',
    paddingBottom: '0.5rem',
    '& hr': {
      margin: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      '& hr': {
        margin: '0px 10px',
      },
    },
  },
  toolbarRightItems: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 64,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0px auto',
    },
  },
  shrinkLogo: {
    maxHeight: '50px',
    maxWidth: '90px',
    color: theme.palette.secondary.main,
  },
  fixedVideoPlayer: {
    position: 'fixed',
    width: '200px',
    right: '50px',
    bottom: '20px',
    zIndex: 1240,
    [theme.breakpoints.up('sm')]: {
      width: '655px',
      maxWidth: '100%',
    },
  },
  channelSelect: {
    backgroundColor: '#000',
    position: 'relative',
    zIndex: 1240,
    '& .MuiSelect-select.MuiSelect-selectMenu': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '20px',
    },
  },
  tvClose: {
    position: 'relative',
    width: '20px',
    float: 'right',
    top: '10px',
    zIndex: 10000,
    right: '10px',
  },
  dialogBackgroundColor: {
    backgroundColor: '#eee6e680',
  },
  frame: {
    width: '655px',
    maxWidth: '100%',
    height: '375px',
    backgroundColor: '#dddddd',
    marginTop: '20px',
    borderRadius: '5px',
  },
  loyaltyContainer: {
    cursor: 'pointer',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: -2,
      left: -2,
      right: -2,
      bottom: -2,
      background: 'linear-gradient(45deg, #fff, #fff, #c8a964, #c8a964, #fff, #fff, #fff, #fff, #fff)',
      backgroundSize: '400%',
      zIndex: -1,
      filter: 'blur(5px)',
      animation: '$glowing 20s linear infinite',
      opacity: 1,
      borderRadius: '10px',
    },
    '&:hover': {
      opacity: 0.8,
      background: 'linear-gradient(45deg, #303030, #303030, #c8a964, #c8a964, #303030, #303030, #303030, #303030, #303030)',
      animation: '$glowing 20s linear infinite',
      '& $loyaltyText': {
        color: '#000',
      },
    },
  },
  loyaltyText: {
    [theme.breakpoints.between('xs', 425)]: {
      fontSize: '10px',
    },
    fontSize: '12px',
    color: '#fff',
    transition: 'color 0.3s ease-in-out',
  },
  loyaltyContent: {
    lineHeight: '.35rem',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4px',
    borderRadius: '8px',
  },
  loyaltyImage: {
    [theme.breakpoints.between('xs', 425)]: {
      width: '30px',
      height: '30px',
    },
    width: '36px',
    height: '36px',
    marginBottom: '4px',
  },
  loyaltyImageMobile: {
    width: '30px',
    height: '30px',
    marginBottom: '4px',
  },
  '@keyframes glowing': {
    '0%': { backgroundPosition: '0 0' },
    '50%': { backgroundPosition: '400% 0' },
    '100%': { backgroundPosition: '0 0' },
  },
  dividerMobile: {},
  cashierButtonMobile: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '15px',
    },
  },
  bonusButtonMobile: {
    minWidth: '35px!important',
    maxWidth: '35px!important',
    span: {
      fontSize: '10px!important',
    },
  },
  balanceUserOptionsMobile: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.between('xs', 374.9)]: {
      marginRight: '8px',
    },
  },
  userBalanceMobile: {
    lineHeight: '1rem',
    textAlign: 'right',
  },
}));

const HeaderButtonViewSelected = GetCurrentHeaderButtonView();
const CurrentHeaderButtonViewInfo = GetCurrentHeaderButtonViewInfo();

export interface AppBarProps {
  cashierDialog: any;
  casinoBonus: CasinoBonusesDto | null;
  casinoBonuses: CasinoBonusesDto[];
  displayAdd: boolean;
  identityDialogOpen: IIdentityDialog;
  isLoading: boolean;
  isTvOn: boolean;
  promotions: any;
  rollover: any;
  runTour: boolean;
  shareable_url: string | null;
  tourSteps: any;
  tvChannelsList: ITvChannels[];
  tvOnOffResponses: boolean;
  tvUrl: string;
  user: any;
  userBalance: any;
  messages: IMessageByUser[];
  isLoadingMessages: boolean;
  unreadMessages: number;
  SetOpenDialogTransactionContestAction: (data: boolean) => void;
  SetRunTourAction: (data: boolean) => void;
  clearCognitoUrlAction: () => void;
  createCognitoRetryAction: () => void;
  getPlayerRolloverAction: (x: any) => void;
  getPromotionAction: () => void;
  getTourStepsAction: () => void;
  getTvChannelsAction: () => void;
  ptpiGetCasinoBonusAction: (x: any) => void;
  ptpiGetCasinoBonusesAction: () => void;
  setCashierDialogAction: (data: any) => void;
  setTvUrlAction: (ch: string) => void;
  toggleVerifyIdentityDialogAction: (state: IIdentityDialog) => void;
  tvOnOffAction: (a: boolean) => void;
  GetMessagesByUserAction: () => void;
  UpdateMessagesByUserMarkAsReadAction: (data: IMessageMarkAsRead) => void;
  setOpenDialogMessages: (data: boolean) => void;
}

export const AppBar = ({
  createCognitoRetryAction,
  displayAdd,
  shareable_url,
  toggleVerifyIdentityDialogAction,
  identityDialogOpen,
  user,
  userBalance,
  tourSteps,
  rollover,
  promotions,
  casinoBonuses,
  unreadMessages,
  getPromotionAction,
  getPlayerRolloverAction,
  getTourStepsAction,
  ptpiGetCasinoBonusesAction,
  ptpiGetCasinoBonusAction,
  setCashierDialogAction,
  cashierDialog,
  //**LIVE TV Vars
  tvOnOffResponses,
  isTvOn,
  tvOnOffAction,
  tvChannelsList,
  isLoading,
  runTour,
  SetRunTourAction,
  clearCognitoUrlAction,
  GetMessagesByUserAction,
  setOpenDialogMessages,
}: // SetOpenDialogTransactionContestAction,
AppBarProps) => {
  const classes = useStyles();
  const [showBonus, setShowBonus] = React.useState<boolean>(false);

  //**LIVE TV Vars
  const defChannel = 'https://tvproj.live/channel1';
  const [channel, setChannel] = React.useState<string>(defChannel);
  const [nUrl, setNUrl] = React.useState<string>('');
  const [v, setV] = React.useState<boolean>(true);
  // const [openCashier, setOpenCashier] = React.useState(false);

  const handleChannel = (value: string) => {
    setV(false);
    const _value = `${value.slice(0, -5)}/prog_index.m3u8`;
    setChannel(value);
    setNUrl(_value);
    setTimeout(() => setV(true), 500);
  };

  useEffect(() => {
    GetMessagesByUserAction();

    const intervalMessages = setInterval(() => {
      GetMessagesByUserAction();
    }, 60000 /* 60 seconds */);

    return () => {
      clearInterval(intervalMessages);
    };
  }, []);

  const getRealAvailableBalanceLabel = (value: number) => {
    return (
      <span style={value < 0 ? { color: 'red' } : value > 0 ? { color: 'green' } : {}}>
        {value < 0 ? `-$${Math.abs(value)}` : `$${value}`}
      </span>
    );
  };

  // Loyalty program
  const [isValidatingLoyalty, setIsValidatingLoyalty] = useState(false);
  const [isLoyaltyEnabled, setIsLoyaltyEnabled] = useState(false);

  useEffect(() => {
    const validateIfUserHasLoyaltyEnabled = async () => {
      try {
        setIsValidatingLoyalty(true);
        const token = 'Bearer ' + String(localStorage.getItem('token'));
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', token);
        const url = `${getAppSettings().apiUrl}/loyalty-program/access`;
        const result = await fetch(url, {
          headers: myHeaders,
        }).then(r => r.json());

        if (result.data.has_access) {
          setIsLoyaltyEnabled(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsValidatingLoyalty(false);
      }
    };
    validateIfUserHasLoyaltyEnabled();
  }, []);

  return (
    <>
      {/* <Cashier isCashierVisible={openCashier} setIsCashierOpen={setOpenCashier} /> */}
      <MuiAppBar position='fixed' color='transparent' elevation={3}>
        <Tour SetRunTourAction={SetRunTourAction} runTour={runTour} getTourStepsAction={getTourStepsAction} tourSteps={tourSteps} />
        <Toolbar classes={{ root: classes.toolbar }}>
          <Hidden xsDown>
            <Link href='#/sportsbook' className='tour-logoCnt'>
              <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
            </Link>
          </Hidden>

          <div className={classes.toolbarRightItems}>
            <Hidden only={['xs']}>
              {HeaderButtonViewSelected == HeaderButtonViewEnum.BRACKET && (
                <Typography className='tour_header-username' style={{ lineHeight: '1rem', textAlign: 'center', marginRight: '20px' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    className='buttonWithMotion'
                    onClick={() => {
                      getHistory().push('/bracket');
                    }}
                  >
                    <span className='buttonWithMotionSpan'>
                      <img src={`${CurrentHeaderButtonViewInfo.image}`} width='60' style={{ marginTop: '0.5rem' }} />
                    </span>
                  </Button>
                  <small style={{ fontSize: '.8rem' }}>{CurrentHeaderButtonViewInfo.name}</small>
                </Typography>
              )}

              {HeaderButtonViewSelected == HeaderButtonViewEnum.PICK && (
                <Typography className='tour_header-bonus' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    className='buttonWithMotion'
                    onClick={() => {
                      getHistory().push('/picks');
                    }}
                  >
                    <span className='buttonWithMotionSpan'>
                      <img src={`${process.env.PUBLIC_URL}/images/brackets/football.png`} width='40' style={{ marginTop: '0.5rem' }} />
                    </span>
                  </Button>
                  <small style={{ fontSize: '.8rem' }}>Contest</small>
                </Typography>
              )}

              <div className='tour_header-username' style={{ display: 'flex' }}>
                <Typography style={{ lineHeight: '1rem', textAlign: 'right' }}>
                  {getRealAvailableBalanceLabel(
                    GetAmountWithOutDecimals(user?.player_type_id === 1 ? userBalance.RealAvailBalance : userBalance.CurrentBalance),
                  )}
                  <br />
                  <small style={{ fontSize: '.7rem' }}>{user.username}</small>
                </Typography>

                <UserIconPopover
                  user={user}
                  userBalance={userBalance}
                  isMobile={false}
                  unreadMessages={unreadMessages}
                  setOpenDialogMessages={setOpenDialogMessages}
                  setCashierDialogAction={setCashierDialogAction}
                />
              </div>
            </Hidden>

            <Hidden xsDown>
              <Divider className={classes.dividerMobile} orientation='vertical' flexItem />
              <Typography className='tour_header-notifications' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Badge
                  badgeContent={unreadMessages.toString()}
                  color='primary'
                  onClick={() => {
                    setOpenDialogMessages(true);
                  }}
                >
                  <EmailIcon />
                </Badge>
              </Typography>
            </Hidden>

            {!isValidatingLoyalty && (
              <Hidden xsDown>
                <Divider className={classes.dividerMobile} orientation='vertical' flexItem />
                {isLoyaltyEnabled && (
                  <div onClick={() => getHistory().push('/loyalty')} className={classes.loyaltyContainer}>
                    <Typography className={`tour_header-loyalty ${classes.loyaltyContent}`}>
                      <img
                        src='https://cdn-sandbox.pdub.io/global/src//loyalty_program/gold.png'
                        alt='Loyalty Icon'
                        className={classes.loyaltyImage}
                      />
                      <small className={classes.loyaltyText}>Loyalty</small>
                    </Typography>
                  </div>
                )}

                {/*               <Typography className='tour_header-bonus' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button variant='contained' color='primary' className='buttonWithMotion' onClick={() => setShowBonus(true)}>
                  <span className='buttonWithMotionSpan' style={{ color: '#fff', fontSize: '12px' }}>
                    Bonus
                  </span>
                </Button>
                <small style={{ fontSize: '12px' }}>${GetAmountWithDecimals(userBalance?.FreePlayAmount ?? 0, 2)}</small>
              </Typography> */}

                {/* <Typography className='tour_header-bonus' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button
                  variant='contained'
                  color='primary'
                  className='buttonWithMotion'
                  onClick={() => SetOpenDialogTransactionContestAction(true)}
                >
                  <span className='buttonWithMotionSpan' style={{ color: '#fff', fontSize: '12px' }}>
                    Contest
                  </span>
                </Button>
              </Typography> */}
              </Hidden>
            )}

            <Hidden xsDown>
              <Divider className={classes.dividerMobile} orientation='vertical' flexItem />
              <Typography className='tour_header-bonus' style={{ lineHeight: '1rem', textAlign: 'center' }}>
                <Button variant='contained' color='primary' className='buttonWithMotion' onClick={() => setShowBonus(true)}>
                  <span className='buttonWithMotionSpan' style={{ color: '#fff', fontSize: '12px' }}>
                    Bonus
                  </span>
                </Button>
                <small style={{ fontSize: '12px' }}>${GetAmountWithDecimals(userBalance?.FreePlayAmount ?? 0, 2)}</small>
              </Typography>
            </Hidden>

            <Hidden xsDown>
              <Divider className={classes.dividerMobile} orientation='vertical' flexItem />

              <Button
                variant='contained'
                color='secondary'
                className='tour_header-cashier'
                startIcon={<FontAwesomeIcon icon={['fad', 'sack-dollar']} />}
                onClick={() => setCashierDialogAction({ isShow: true, type: 'cashier' })}
                // onClick={() => setOpenCashier(true)}
              >
                <Typography variant='button'>Cashier</Typography>
              </Button>
            </Hidden>

            <Hidden xsDown>
              <Divider className={classes.dividerMobile} orientation='vertical' flexItem />

              <IconButton
                color='inherit'
                className='tour_header-chat'
                onClick={() => {
                  // openLOLiveChat();
                  getHistory().push('/live-chat');
                }}
              >
                <FontAwesomeIcon icon={['fad', 'comment']} />
              </IconButton>
            </Hidden>
          </div>

          {/* mobile menu */}
          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            {/* cashier */}
            <Button
              variant='contained'
              color='secondary'
              className={`${classes.cashierButtonMobile} tour_header-cashier`}
              onClick={() => setCashierDialogAction({ isShow: true, type: 'cashier' })}
            >
              <FontAwesomeIcon icon={['fad', 'sack-dollar']} size='2x' />
            </Button>

            {/* lotalty */}
            {!isValidatingLoyalty && (
              <>
                <Divider orientation='vertical' flexItem className={`dividerTop ${classes.dividerMobile}`} />

                {isLoyaltyEnabled && (
                  <div onClick={() => getHistory().push('/loyalty')} className={classes.loyaltyContainer}>
                    <Typography className={`tour_header-loyalty ${classes.loyaltyContent}`}>
                      <img
                        src='https://cdn-sandbox.pdub.io/global/src//loyalty_program/gold.png'
                        alt='Loyalty Icon'
                        className={classes.loyaltyImageMobile}
                      />
                      <small className={classes.loyaltyText}>Loyalty</small>
                    </Typography>
                  </div>
                )}
              </>
            )}

            {/* messages */}
            {/*<Divider className={classes.dividerMobile} orientation='vertical' flexItem />

            <Typography className='tour_header-notifications' style={{ lineHeight: '1rem', textAlign: 'center' }}>
              <Badge
                badgeContent={unreadMessages.toString()}
                color='primary'
                onClick={() => {
                  setOpenDialogMessages(true);
                }}
              >
                <EmailIcon />
              </Badge>
            </Typography>*/}

            {/* bonus */}
            <Divider orientation='vertical' flexItem className={`${classes.dividerMobile}`} />

            <Typography className='tour_header-bonus' style={{ lineHeight: '1rem', textAlign: 'center' }}>
              <Button
                variant='contained'
                color='primary'
                className={`${classes.bonusButtonMobile} buttonWithMotion`}
                onClick={() => setShowBonus(true)}
              >
                <span className='buttonWithMotionSpan' style={{ color: '#fff', fontSize: '12px' }}>
                  Bonus
                </span>
              </Button>
              <small style={{ fontSize: '12px' }}>${GetAmountWithDecimals(userBalance?.FreePlayAmount ?? 0, 2)}</small>
            </Typography>

            {/* balance, user and options */}
            <Typography className={classes.balanceUserOptionsMobile}>
              <Divider orientation='vertical' flexItem className={`${classes.dividerMobile}`} />

              <Typography className={`${classes.userBalanceMobile} tour_header-username`}>
                {getRealAvailableBalanceLabel(
                  GetAmountWithOutDecimals(user?.player_type_id === 1 ? userBalance.RealAvailBalance : userBalance.CurrentBalance),
                )}

                <br />

                <small style={{ fontSize: '.8rem' }}>{user.username}</small>
              </Typography>

              <UserIconPopover
                user={user}
                userBalance={userBalance}
                isMobile={true}
                unreadMessages={unreadMessages}
                setCashierDialogAction={setCashierDialogAction}
                setOpenDialogMessages={setOpenDialogMessages}
              />
            </Typography>
          </Hidden>
        </Toolbar>

        {displayAdd && <AdDialog setCashierDialogAction={setCashierDialogAction} />}

        {identityDialogOpen.open == true && (
          <IdentityDialog
            toggleIdentityDialog={toggleVerifyIdentityDialogAction}
            identityDialogOpen={identityDialogOpen.open}
            shareable_url={shareable_url}
            createCognitoRetryAction={createCognitoRetryAction}
            cognitoMatch={user.cognito_match}
            message={identityDialogOpen.message}
            isLoading={isLoading}
            success={identityDialogOpen.result}
            playerProfileSettingsPaymentMethodBonuses={identityDialogOpen.playerProfileSettingsPaymentMethodBonuses}
            clearCognitoUrlAction={clearCognitoUrlAction}
          />
        )}
      </MuiAppBar>

      <Dialog
        open={showBonus}
        onClose={() => setShowBonus(false)}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        PaperProps={{
          style: {
            backgroundColor: '#fff8f833',
            margin: '1%',
          },
        }}
      >
        <DialogBonusCard
          promotions={promotions}
          getPromotionAction={getPromotionAction}
          setShowBonus={setShowBonus}
          rollover={rollover}
          getPlayerRolloverAction={getPlayerRolloverAction}
          playerNumber={user.username}
          casinoBonuses={casinoBonuses}
          ptpiGetCasinoBonusesAction={ptpiGetCasinoBonusesAction}
          ptpiGetCasinoBonusAction={ptpiGetCasinoBonusAction}
          isLoading={isLoading}
          user={user}
        ></DialogBonusCard>
      </Dialog>

      <CashierDialog cashierDialog={cashierDialog} onClose={() => setCashierDialogAction({ isShow: false, type: 'cashier' })} user={user} />

      {isTvOn && (
        <Draggable handle='#draggable-fixed-video' cancel={'[class*="MuiDialogContent-root"]'}>
          <div className={classes.fixedVideoPlayer} aria-labelledby='draggable-fixed-video'>
            <div className={classes.tvClose}>
              <CancelIcon onClick={() => tvOnOffAction(!tvOnOffResponses)} />
            </div>
            {v && (
              <div id='draggable-fixed-video'>
                <Player url={nUrl} />
              </div>
              // <iframe id='draggable-fixed-video' src={channel} className={classes.frame} frameBorder='0'></iframe>
            )}
            <FormControl fullWidth className={classes.channelSelect}>
              <Select fullWidth value={channel} onChange={e => handleChannel((e.target.value as unknown) as string)}>
                <MenuItem value={defChannel}>
                  <em>Select Channel</em>
                </MenuItem>
                {tvChannelsList
                  .filter(item => item.is_visible && item.channel_embed_url !== '')
                  .map((item: any, i: number) => {
                    return (
                      <MenuItem value={item.channel_embed_url} key={i}>
                        <img
                          src={`https://dev-api.pdub.lv/api/tv/get-image-from-url?url=${encodeURIComponent(item.logo)}`}
                          width='50'
                          height='50'
                          style={{ marginRight: '10px' }}
                        />
                        {item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
        </Draggable>
      )}
    </>
  );
};
