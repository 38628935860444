import React, { createContext, useContext, useState, useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

interface UserMetadataContextType {
  ip: string | null;
  fingerprint: string | null;
}

const UserMetadataContext = createContext<UserMetadataContextType>({
  ip: null,
  fingerprint: null,
});

export const useUserMetadata = () => useContext(UserMetadataContext);

/* eslint-disable-next-line */
export const UserMetadataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ip, setIp] = useState<string | null>(null);
  const [fingerprint, setFingerprint] = useState<string | null>(null);

  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '') : null;

  // Fetch fingerprint
  const fetchFingerprint = async () => {
    try {
      // TODO: GET TOKEN FROM ENV SECRETS.
      const fp = await FingerprintJS.load({ token: 'nvoiB2OqFxGoBIg53MC6' });
      const result = await fp.get({ tag: { username: user?.username, ip } });
      setFingerprint(result.visitorId);
      localStorage.setItem('fpjs', result.visitorId);
    } catch (error) {
      console.error('Error fetching fingerprint:', error);
      setFingerprint(null);
    }
  };

  useEffect(() => {
    // Fetch IP address
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIp(data.ip);
        localStorage.setItem('geo', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    // Fetch fingerprint
    fetchFingerprint();

    if (!ip) {
      fetchIp();
    }
  }, [ip]);

  useEffect(() => {
    // Refetch fingerprint when user or ip changes
    const refetchFingerprint = async () => {
      if (ip && user) {
        await fetchFingerprint();
      }
    };

    refetchFingerprint();
  }, [user, ip]);

  return <UserMetadataContext.Provider value={{ ip, fingerprint }}>{children}</UserMetadataContext.Provider>;
};
