import Axios from 'axios';
import { main } from './app';
import * as serviceWorker from './serviceWorker';
import { getAppSettingsName } from './utils';

const APP_ID = 'desking';

const appsettingsConfig = getAppSettingsName();
if (appsettingsConfig.async) {
  main(APP_ID, null);
} else {
  Axios.get(appsettingsConfig.url)
    .then(response => {
      const appSettings = response.data;
      main(APP_ID, Object.freeze(appSettings));
    })
    .catch(error => {
      console.error(`Failed to load APP SETTINGS:`, error);
    });
}

serviceWorker.unregister();
//loadAppSettings();
// serviceWorker.unregister();
